<template>
  <div class="global"> 



         <!--  
  <div class="top"> 
    <div class="topleft"> 
         <h1> Kirmanc.info</h1>
     </div>  



     <div class="topright"> 
            <div class="topright_top"> 
    


  
                 <select class="sitelanguageselect" name="country" v-model="sitelanguage"  @change="setsitelanguage(sitelanguage)">
                    <option selected="" disabled="" value="">--Select language--</option>
                    <option value="tr">turkce </option>
                    <option value="kc">kirmanc</option>
                    <option value="en">english</option>
                    <option value="fr">francais</option>
                    <option value="de">deutsch</option>   
                    <option value="nl">niederländisch</option>  
                   </select>


                 



              </div>  



            
                   <div class="topright_bottom"> 
                      information
                  </div>  



     </div>  

   



  </div>


 

  -->    



  <div class="kirmanc">
 
     <div class="kirmanccontent">

         <!--
        <div class="headerlanguage" v-if="false">
        <a  class="lsite"  @click="sitelanguage='tr'" >turkce</a>
         <a class="lsite"   @click="sitelanguage='kc'">kirmanc</a>
          <a  class="lsite"   @click="sitelanguage='fr'">francais</a>
          <a class="lsite"   @click="sitelanguage='en'">english</a>
          <a class="lsite"   @click="sitelanguage='de'">deutsch</a>
          <a class="lsite"   @click="sitelanguage='nl'">niederländisch</a>


         </div>
-->

      <span class="title"> {{title[sitelanguage]}} </span>
  
       

      <div class="topsection">
          <div class="topsection1">
            <select class="languageselect" name="country" v-model="ll1"  >
                    <option selected="" disabled="" value="">--Select Country--</option>
                    <option value="tr"> {{listlanguage[sitelanguage]["tr"]}}</option>
                    <option value="kc">{{listlanguage[sitelanguage]["kc"]}}</option>
                    <option value="en">{{listlanguage[sitelanguage]["en"]}}</option>
                    <option value="fr">{{listlanguage[sitelanguage]["fr"]}}</option>
                    <option value="de">{{listlanguage[sitelanguage]["de"]}}</option>   
                    <option value="nl">{{listlanguage[sitelanguage]["nl"]}}</option>  
                </select>

              <!--
                 {{listlanguage[sitelanguage][ll1]}}
               -->

              
             


          </div>
          <div class="topsection2" @click="revert">
        
            
              <fa icon="exchange-alt" />
            
              
             
          </div>
          <div class="topsection3">
            <select class="languageselect" name="country" v-model="ll2"  >
                    <option selected="" disabled="" value="">--Select Country--</option>
                    <option value="tr"> {{listlanguage[sitelanguage]["tr"]}}</option>
                    <option value="kc">{{listlanguage[sitelanguage]["kc"]}}</option>
                    <option value="en">{{listlanguage[sitelanguage]["en"]}}</option>
                    <option value="fr">{{listlanguage[sitelanguage]["fr"]}}</option>
                    <option value="de">{{listlanguage[sitelanguage]["de"]}}</option>   
                    <option value="nl">{{listlanguage[sitelanguage]["nl"]}}</option>  
                </select>
              
                   <!--
                       {{listlanguage[sitelanguage][ll2]}}
                    -->

                   
           </div>   
          
  



      </div>



      <div class="formlogin1">
        <div class="rowline">
    
          <label class="rowlinelabel">{{l1[sitelanguage]}} :</label>
          <input type="text"  v-model="oword" placeholder="" required/>
        </div>
       
        <button type="submit" @click="submit2(this.oword, this.ll2,this.ll1)" >{{btitle[sitelanguage]}}</button>
        
        
          <div class="rowline">
       
          <label class="rowlinelabel">{{l2[sitelanguage]}} : </label>
           <div class="divresult">

                 <p v-for="value in result" v-bind:key="value.id">
                      {{value[ll2]}}
                    
                  </p>

                   
           </div>
         
         <div class="countourchange"> 
              <div class="countourchange_title"  @click="changebox=!changebox"> 
                      <label class="labelproposeachange"  > {{changeword.word1[sitelanguage]}}  </label>
                     <div style=" align-self: flex-end;">
                        <p> <b> V</b></p>
                     </div>
                    
             </div>
          
                      <div class="changebox" v-if="changebox">
                              <p class="pchangeline"> {{changeword.word2[sitelanguage]}} &nbsp;   {{ oword }}</p>
                          
                              <input class="inputword" type="text"  v-model="changetranslation" placeholder="" required/>
                             
                              <p class="pchangeline">  {{changeword.word3[sitelanguage]}}   </p>
                          
                               <input class="inputword" type="text" v-model="changecomment"  placeholder="" required/>

                                  <button  class="changebutton" @click="submitchange()" >  {{changeword.word4[sitelanguage]}} </button>
                  
                        </div>
        
                   

                          <transition name="fade" >
                            <p v-if="changesubmitted" class="messagesbmission">{{ thankmessage[sitelanguage]}} </p>
                          </transition>

                


         </div>  
           
         
         <div class="countourchange"> 
                          <div class="countourchange_title" @click="newwordbox=!newwordbox"> 
                                      <label class="labelproposeachange"  >{{addword.word1[sitelanguage]}}</label>
                                    <div style=" align-self: flex-end;">
                                        <p> <b> V</b></p>
                                    </div>
                                    
                            </div>


                   <div class="newwordbox" v-if="newwordbox">
                            <p class="pchangeline"> {{addword.word2[sitelanguage]}}  &nbsp;   {{listlanguage[sitelanguage][ll1]}} </p>
                           
                            <input class="inputword"  type="text" v-model="newword"  placeholder="" required/>
                                  
                         <p class="pchangeline"> {{addword.word3[sitelanguage]}}  &nbsp;   {{listlanguage[sitelanguage][ll2]}} </p>
                          
                         <input class="inputword" type="text"  v-model="newwordtranslate" placeholder="" required/>

                                    <button  class="newbutton"  @click="addnewtranslation()"> {{addword.word4[sitelanguage]}}</button>
                    
                          </div>

                          <transition name="fade" >
                            <p v-if="addsubmitted" class="messagesbmission">{{addword.word1[sitelanguage]}}</p>
                          </transition>

        </div>  

     
        </div>
      </div>

    </div>

  </div>
  </div>
</template>

<script>


import axios from "axios";
import {createNamespacedHelpers} from 'vuex';

//import AxiosPlugin from 'vue-axios-cors';

const dictionary= createNamespacedHelpers('dictionary');


export default {
  name: 'Kirmanc',
  props: {
    
  },
  data() {
    return {
      oword:'',
      result:'',
      showError: false,
      language1: {show:"Türkçe", code:"tr", dictionary:"tr"},
      language2:  {show:"Kirmanc", code:"kc", dictionary:"kc"},
    //  sitelanguage:"tr", 
      ll1:"tr",
      ll2:"kc",
      listlanguage: {tr:{tr:"türkçe", kc:"kirmançça", fr:"fransızca", en:"ingilizce", de:"almanca", nl:"hollandaca"}, 
                     kc:{tr:"türkçe", kc:"kirmançça", fr:"fransızca", en:"ingilizce", de:"almanca", nl:"hollandaca"}, 
                     fr:{tr:"turc", kc:"kirmanc", fr:"français", en:"anglais", de:"allemand", nl:"néerlandais"},
                     en:{tr:"turkish", kc:"kirmanc", fr:"french", en:"english", de:"german", nl:"dutch"},
                     de:{tr:"türkisch", kc:"kirmac", fr:"französisch", en:"englisch", de:"deutsch", nl:"niederländisch"}, 
                     nl: {tr:"turks", kc:"kirman", fr:"frans", en:"engels", de:"duits", nl:"nederlands"}
                     },
     
      title:{"tr":"Tercüme", "en":"Translation", "fr":"Traduction", "kc":"Tercume","de":"Übersetzung" , "nl":"Vertaling"},
      btitle:{"tr":"Tercüme et", "en":"Translate", "fr":"Traduit", "kc":"Tercume", "de":"Übersetzen" , "nl":"Vertalen"},
      l1:{"tr":"Tercüme edilicek kelime", "en":"Word to translate", "fr":"Mot à traduire", "kc":"Tercüme edilicek kelime", "de":"Wort zu übersetzen" , "nl":"Woord om te vertalen"},
      l2:{"tr":"Sonuç", "en":"Result", "fr":"Résultat", "kc":"Sonuç", "de":"Ergebnis" , "nl": "Resultaat"},
    
      changebox:false,
      newwordbox:false,
      changetranslation:"",
      changecomment:"",
      newword:"",
      newwordtranslate:"",
    //  url:"http://localhost:8084/one/",
      url:"https://www.kirmanc.info/",
      changesubmitted :false,
      addsubmitted :false,
      changeword:{word1:{en:'Propose a new translation',fr:'Proposer une nouvelle traduction',tr:'Yeni bir çeviri önermek',de:'Eine neue Übersetzung vorschlagen',nl:'Stel een nieuwe vertaling voor',kc:'Yeni bir çeviri önermek'},word2:{en:'Propose a new translation for this word:',fr:'Proposez une nouvelle traduction pour ce mot:',tr:'Bu kelime için yeni bir çeviri önerin:',de:'Schlagen Sie eine neue Übersetzung für dieses Wort vor:',nl:'Stel een nieuwe vertaling voor dit woord voor:',kc:'Bu kelime için yeni bir çeviri önerin:'},word3:{en:'Optional : propose explanation for your change',fr:'Facultatif: proposez une explication de votre changement',tr:'İsteğe bağlı: Değişikliğinizin bir açıklamasını sunun',de:'Optional: Bieten Sie eine Erklärung Ihrer Änderung an',nl:'Optioneel: bied een uitleg van uw verandering',kc:'İsteğe bağlı: Değişikliğinizin bir açıklamasını sunun'},word4:{en:'Submit new translation',fr:'Soumettre une nouvelle traduction',tr:'Yeni Çeviri Gönderin',de:'Neue Übersetzung einreichen',nl:'Dien nieuwe vertaling in',kc:'Yeni Çeviri Gönderin'}},
      addword:{word1:{en:'Add a new word',fr:'Ajouter un nouveau mot',tr:'Yeni bir kelime ekle',de:'Fügen Sie ein neues Wort hinzu',nl:'Voeg een nieuw woord toe',kc:'Yeni bir kelime ekle'},word2:{en:'New word in :',fr:'Nouveau mot en:',tr:'Yeni kelime:',de:'Neues Wort in:',nl:'Nieuw woord in:',kc:'Yeni kelime:'},word3:{en:'Propose translation in :',fr:'Proposez la traduction en:',tr:'Çeviriyi önerin:',de:'Schlagen Sie die Übersetzung in: vor:',nl:'Stel de vertaling voor in:',kc:'Çeviriyi önerin:'},word4:{en:'Submit new word',fr:'Soumettre un nouveau mot',tr:'Yeni kelime gönder',de:'Senden Sie ein neues Wort',nl:'Een nieuw woord indienen',kc:'Yeni kelime gönder'}},
      thankmessage:{en:'Thank you for your submission',fr:'Merci pour votre soumission',tr:'Gönderiminiz için teşekkür ederiz',de:'Vielen Dank für Ihre Einreichung',nl:'Dank u voor uw inzending',kc:'Gönderiminiz için teşekkür ederiz'}


    };
  },

  computed: {
   ...dictionary.mapState(['sitelanguage']),
// ...dictionary.mapGetters([ 'getsitelanguage']),
/*
   updatelangauge(){
    this.sitelanguage=this.sitelanguage;
   },
   */
  
  },

  methods: {

    ...dictionary.mapActions(['setsitelanguage']), 

         revert(){
        const cl1=this.ll1;
        const cl2=this.ll2;
        this.ll1=cl2;
        this.ll2=cl1;
        

         },

        async submit2(word, targetl, dictionary) {
                   
      
                  const mdat2 = new FormData();
                   mdat2.append('submit', true);
                   mdat2.append('targetl', targetl);
                   mdat2.append('word', word);
                   mdat2.append( 'dictionary', dictionary );


                
                    try {
                    
                     await axios.post(this.url+"dictionary.php",mdat2).then((res) => {
                    //    await axios.post(this.url+"dictionary.php",mdat2).then((res) => {
                 
                 //     await   axios.post('https://www.kirmanc.info/dictionary.php',  mdat2).then((res) => {
                          console.log(res.data);
                     //     const nr= (res.data).length;
                   //        console.log("targetl : " + targetl + " dictionary :" + dictionary + " word :  "+word);
                          this.result=res.data;

            /*
                         this.result="";

                          for (let i=0; i< nr;i++){
                           this.result+=(res.data)[i]["tr"] + "\n" ;
                          }
                          */
                        })
                              
                        this.showError = false;
                       
                        
                    } catch (error) {
                      this.showError = true;
                      this.result="";

                    }
                            
                 },


           async submitchange() {
                   
      
                   const mdat2 = new FormData();
                    mdat2.append('submit', true);
                    mdat2.append( 'dictionary', this.ll1 );
                    mdat2.append('targetl', this.ll2);
                    mdat2.append('word', this.oword);
                    mdat2.append('translation', this.changetranslation);
                    mdat2.append('comment', this.changecomment);
                       
                     try {       
                         await axios.post(this.url+"changeword.php",mdat2).then((res) => {
                  
                  //     await   axios.post('https://www.kirmanc.info/dictionary.php',  mdat2).then((res) => {
                           console.log(res.data);
                      //     const nr= (res.data).length;
                          this.changesubmitted =true;

                          const that=this; 

                          setTimeout(function() {
                            that.changesubmitted =false;
                              }, 1000);

                        
                                        
                         })
                               
                         this.showError = false;
                        
                         
                     } catch (error) {
                       this.showError = true;
                       this.result="";
 
                     }
                             
                  },


                  async addnewtranslation() {
                   
      
                   const mdat2 = new FormData();
                    mdat2.append('submit', true);
                    mdat2.append( 'dictionary', this.ll1 );
                    mdat2.append('targetl', this.ll2);
                    mdat2.append('word', this.newword);
                    mdat2.append('translation', this.newwordtranslate);
              
                     try {       
                         await axios.post(this.url+"addword.php",mdat2).then((res) => {
                  
                  //     await   axios.post('https://www.kirmanc.info/dictionary.php',  mdat2).then((res) => {
                          console.log(res.data);
                      //     const nr= (res.data).length;

                      this.addsubmitted =true;

                          const that=this; 

                          setTimeout(function() {
                            that.addsubmitted =false;
                              }, 1000);
                 
                         })
                               
                         this.showError = false;
                        
                         
                     } catch (error) {
                       this.showError = true;
                       this.result="";
 
                     }
                             
                  },

  /*
                  closemessage_changesubmitted(){
                    var that = this;
                    setTimeout(function() {
                            that.changesubmitted =false;
                              }, 3000);
                  },

                  */
 
        
        },
        created () {
        // this.sitelanguage = this.sitelanguage  ;

         //  console.log(this.sitelanguageselect)
        }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.global{
  display: flex;
  flex-direction: column;
  width:420px;
}

.top{
  display: flex;
  justify-content: left;
  padding: 0 0 0 30px;
  color: white; 
  background:darkblue;
}


.topright{
  display: flex;
  flex-direction: column;
  flex:4;
  color: white; 
  background:darkblue;
}

.topright_top {
  display: flex;
  justify-content: right;
  
  padding: 10px 20px 0 30px;
  color: white; 
  background:darkblue;
}

.topright_bottom {
  display: flex;
  justify-content: right;
  padding: 20px 20px 0 30px;
  color: white; 
  background:darkblue;
}


.topleft{
  display: flex;
  flex:1;
  display: flex;
  justify-content: left;
  padding: 20px 0 0 30px;
  color: white; 
  background:darkblue;
}


.kirmanc{
  display: flex;
  justify-content: center;
  padding: 30px 0;

}

.kirmanccontent {
        display: flex;  
        width: 450px;
        padding: 20px 40px;
        justify-content:center;
        flex-direction: column;
         border: 1px solid rgb(216, 216, 216);
         border-radius: 5px;
       
            
      }

.title{
             font-size: calc(1.375rem + 1.5vw);
            font-family: sans-serif;
            margin: 10px 0 20px 0;
            
}


.topsection{
 display: flex;
 justify-content: space-between;
 align-items: center;
 max-height: 50px;
 margin :10px 0 10px 0 ;
}
.topsection1{
 display: flex;
 flex:2;
 justify-content: space-between;
 align-items: center;
  font-weight: bold;

  flex-direction: column;
}

.topsection2{
 display: flex;
 align-items: center;
 flex:1;
 min-height: 50px;
  justify-content: center;
  align-items: center;
  font-size: 20px strong;
  color: blue;
   font-weight: bold;
  
}

.topsection2 :hover{
 
       font-size: 25px;
       font: red ;

}


.topsection3{
 display: flex;
 flex-direction: column;
 flex:2;
 justify-content: right;;
  align-items: center;
  align-content: right;
   font-weight: bold;
 
}




.formlogin1{
margin:0px;
padding: 0px;;

}



.rowlinelabel {
  padding: 0;
  margin:0;
  text-align: left;
  
}

button[type="submit"] {
  background-color: #0d6efd;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin: 15px 00px 30px 00px;
  width: 100%;
}
button[type="submit"]:hover {
  background-color: #285fb3;
   transition: 0.5s ease all;
}


.changebutton {
  background-color: #5493f0;
  color: white;
  padding: 0px 10px;
 
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  align-self: center;
  width: 300px;
  height: 30px;
 
  text-align: center;
  justify-content: center;
}
.changebutton:hover {
  background-color: #285fb3;
   transition: 0.5s ease all;
}


.newbutton {
  background-color: #5493f0;
  color: white;
  padding: 0px 10px;
 
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  align-self: center;
  width: 300px;
  height: 30px;
 
  text-align: center;
  justify-content: center;
}
.newbutton:hover {
  background-color: #285fb3;
   transition: 0.5s ease all;
}





.rowline{
display: flex;
flex-direction: column;
}

.rowline input {
  margin: 0 0 20px 0;
 /* box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06); */
  padding: 5px 5px;
 
   border: 1px solid rgb(216, 216, 216);
         border-radius: 0.5px;
 
}

.divresult{
 display: flex;
 flex-direction: column;
 text-align:left;
 padding: 5px;
  min-height: 100px;
  width: 100%;
  border: 1px solid rgb(216, 216, 216);
         border-radius: 0.5px;

}

.headerlanguage {
 padding:5px;
 display:flex;
 flex-wrap:wrap;

}
.languageselect {
width:130px;
}
.sitelanguageselect{
  width:150px;
  height: 40px;
  padding-left: 10px;

  color: blue;
 
}

.lsite  {
margin: 0 5px;
text-decoration: none;
   color: blue;
    flex-basis: 40%;
}

a:hover, a:active {
   font-weight: bold;
   color:black;
}
a:link {
  text-decoration: none;
color: blue;

}


.labelproposeachange{
  

  text-align: left;
  color: blue;
}


.labelnewword{


  text-align: left;
  color: blue;
}


.changebox{
display: flex;
flex-direction: column;
align-items: flex-start;
}



.pchangeline{
display: flex;
margin-top: 20px;
text-align: left;
align-content:flex-start;
}


.countourchange{
  display: flex;
  flex-direction: column;
  margin-top:30px;
  padding: 20px;
  border: 1px solid rgb(57, 64, 210);
         border-radius: 0.5px;

}

.countourchange_title{
  display: flex;
  justify-content: space-between;
  color: blue;
}

.inputword{
width:100%;
min-height: 40px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}


.messagesbmission{
  margin-top:20px;
color:rgb(150, 97, 97)

}

</style>
