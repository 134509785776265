import { createApp } from 'vue'
import App from './App.vue'

//import { createStore } from 'vuex'


import store from './store/'

import vClickOutside from "click-outside-vue3"

import axios from 'axios'

import VueGtag from "vue-gtag";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'

/* import font awesome icon component */
 import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

 import router from './router'

//import FontAwesomeIcon from "@/libs/FontAwesomeIcon.vue";

/* add icons to the library */
library.add(fas);


import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"



axios.defaults.withCredentials = true


// the following might point to the right folder which contains login and register files
axios.defaults.baseURL = 'https://www.kirmanc.info';




//createApp(App).mount('#app')

const app = createApp(App)


app.use(store); 
app.use(router);

app.use(vClickOutside);


app.use(VueGtag,{
    config: { id: "G-W48CXF823W" }
  });


app.component('fa', FontAwesomeIcon);


app.mount('#app');




