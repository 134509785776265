
//import Vue from 'vue'
//import Vuex from 'vuex';

import {createStore} from 'vuex'
import dictionary from './modules/dictionary'


// the following is used to have vue pages that are not reset after relad

import createPersistedState from "vuex-persistedstate";

// Create a new store instance.
const store = createStore({
    modules:{
       dictionary
    }, 
    plugins: [createPersistedState()]
})
export default store;

