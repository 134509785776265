<template>
    <div class="alfabetdiv">
<h1>     ALFABE        </h1>
    <br>
<h1>     KIRMANCKİ ‡ TIRKİ          </h1>
  <p>   </p>      

<div class="alfalist"> 


<p> A a ....... asmen, asmı, awıkı ......... at, amca, anne  </p>  
<p> B b ....... bon, borı, bari ......... boncuk, baba, başvulmuştur </p>  
<p> C c ....... ca, cacım. caritı ......... civciv, cimri, cahil </p>  
<p> C c ....... ca, cacım. caritı ......... civciv, cimri, cahil </p>  
<p> Ç ç ....... Çad, çek, çicegı ......... Çad, çiçek, çukur </p>  
<p> D d ....... dest, derı, Dersım ......... Dersim, dede, ayı </p>  
<p> E e ....... ecdad, ekı, emser ......... Elazığ, ebe, emin </p>  
<p> É é ....... émın, éro, éwk, éré </p>  
<p> F f ....... Fransa, fek, fizik ......... fitil, Fas, fırın </p>  
<p> G g ....... gıran, Gana, galı ......... gazi, gar, gardiyan </p>  
<p> H h ....... haval, Hollanda, hal ......... halı, Hazro, Hozat </p>  
<p> I ı ....... ırq, Iraq, ırz ......... ızdırap, ılıman, ışık </p>  
<p> İ i ....... İran, insan, İsa ......... izin, incir, itiraz </p>  
<p> J j ....... ju, Japonya, juri ......... jandarma, jilet, jet </p>  
<p> K k ....... kokım, Kırmac, kılm ......... Kürt, kelam, kalem </p>  
<p> L l ....... lawkı, lacek, lela ......... lale, lazım, lakap </p>  
<p> M m ....... mordem, malım, mast ......... modern, Mardin, mektup </p>  
<p> N n ....... namı, na, nare ......... nasıl, narin, nal </p>  
<p> O o ....... Oslo, ostor, otonomi ......... orta, olur, omuz </p>  
<p> P p ....... pala, pacık, peyra ......... papatya, pamuk, pancar </p>  
<p> Q q ....... qeyda, qelemı, qumaş, qutiye </p>  
<p> R r ....... raşt, rü, raye ......... rahat, razı, rica </p>  
<p> S s ....... sodır, sanıkı, soba ......... saat, selam, sarı </p>  
<p> Ş ş ....... şa, Şam, şah ......... şimdi, şafak, şair </p>  
<p> T t ....... tam, tari, taqib ......... tren, taş, tahta </p>  
<p> U u ....... uca, urcı, ucız ......... ucuzluk, uzun, uzak </p>  
<p> Ü ü ....... Ürdün, ümmet, ütü ......... üzüm, üzgün ünlü </p>  
<p> V v ....... Van, veng, varıs ......... vatan. varlık, viran </p>  
<p>  W w ....... welat, waye, warı, waştiye </p>  
<p>  X x ....... xanım, xal. xora, xort </p>  
<p> Y y ....... yaman, yaban, yan ......... yedi, yayla , yarın </p>  
<p> Z z ....... zarar, zedı, zerenc ......... zeki, zarf, zaza </p>  

</div>

    </div>

</template>






<script>


import {createNamespacedHelpers} from 'vuex';

const dictionary= createNamespacedHelpers('dictionary');



export default {
  name: "Alfabet",
  components: {},
  data() {
    return {
    
    
    };
  },

  computed: {
   ...dictionary.mapState(['sitelanguage']),
  },

  methods: {
   

   },
 
};
</script>

<style scoped>
.alfabetdiv {
 display: flex;
 flex-direction: column;
 align-items: center;
 padding-top: 30px;
 padding-left:10px

}

.alfalist{
display:flex;
flex-direction: column;
align-items:flex-start;
text-align: left;

/* width: 400px;
*/

}


</style>