<template>
    <div class="alfabetdiv">
<h1>        {{ commandtext[sitelanguage] }}      </h1>
    <br>

  <p>  
    {{ buymessage[sitelanguage] }}
    <!--
    if you want to support our activities, you can order the published dictionary by clicking on the picture.
    -->  
  </p>      



<div class="alfalist"> 
    <a href="https://www.amazon.com.tr/Fehrenge-K%C4%B1rmancki-T%C4%B1rki-T%C3%BCrk%C3%A7e-K%C4%B1rmancki-S%C3%B6zl%C3%BCk/dp/9758674420" target="_blank">  
    <img :src=linkbook alt="Logo" style="max-width:300px;">
    </a>

</div>

    </div>


</template>






<script>


//import {createNamespacedHelpers} from 'vuex'




//const { mapState, mapActions } = createNamespacedHelpers('auth');


import {createNamespacedHelpers} from 'vuex';


const dictionary= createNamespacedHelpers('dictionary');



export default {
  name: "Book",
  components: {},
  data() {
    return {

        linkbook:"https://m.media-amazon.com/images/I/418Bi5xykPL._SX311_BO1,204,203,200_.jpg",
        buymessage:{en:'if you want to support our activities, you can order the published dictionary by clicking on the picture.',fr:'Si vous souhaitez soutenir nos activités, vous pouvez commander le dictionnaire publié en cliquant sur l'+"'"+'image.',tr:'Etkinliklerimizi desteklemek istiyorsanız, resme tıklayarak yayınlanan sözlüğü sipariş edebilirsiniz.',de:'Wenn Sie unsere Aktivitäten unterstützen möchten, können Sie das veröffentlichte Wörterbuch bestellen, indem Sie auf das Bild klicken.',nl:'Als u onze activiteiten wilt ondersteunen, kunt u het gepubliceerde woordenboek bestellen door op de foto te klikken.',kc:'Etkinliklerimizi desteklemek istiyorsanız, resme tıklayarak yayınlanan sözlüğü sipariş edebilirsiniz.'},
        commandtext:{en:'Order the printed dictionary',fr:'Commandez le dictionnaire imprimé',tr:'Basılı sözlüğü sipariş edin',de:'Bestellen Sie das gedruckte Wörterbuch',nl:'Bestel het gedrukte woordenboek',kc:'Basılı sözlüğü sipariş edin'},

    
    
    
    };
  },

  computed: {
   ...dictionary.mapState(['sitelanguage']),
  },

  methods: {

   },
 
};
</script>

<style>

.alfabetdiv {
 display: flex;
 flex-direction: column;
 align-items: center;
 padding: 30px 0  30px 0 ;

}

.alfalist{
display:flex;
flex-direction: column;
align-items: center;

margin-bottom: 30px;

}
</style>