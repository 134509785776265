<template>

<div class="master">


<div class="top"> 

    <div class="toptop">
        
           <div class="topleft"> 

            <router-link class="menulink" to="/Kirmanc" >
              
                <h1> Kirmanc.info</h1>    
            </router-link> 

            </div>  


            <div class="topright_top"> 
         
                <select class="sitelanguageselect" name="country" v-model="languagesite2"  @change="updatelanguage">
                    <option selected="" disabled="" value="">--Select language--</option>
                    <option value="tr">turkce </option>
                    <option value="kc">kirmanc</option>
                    <option value="en">english</option>
                    <option value="fr">francais</option>
                    <option value="de">deutsch</option>   
                    <option value="nl">niederländisch</option>  
                  </select>
                  
              </div>  

    </div>



     <div class="topbottom"> 
           
    
                   <div class="topright_bottom"> 
                   
                           <router-link class="menulink" to="/Kirmanc" >  {{ Dictionarytt[sitelanguage] }} </router-link> 
                        
                           <router-link class="menulink" to="/Purpose" >  {{ Infott[sitelanguage] }} </router-link> 
                           
                         <!--

                           <router-link class="menulink" to="/Information" >  {{ Infott[sitelanguage] }} </router-link> 
                         
                           -->  


                           <router-link class="menulink" to="/Alfabet" >  {{ Alphabettt[sitelanguage] }} </router-link> 
                         
                         
                            <router-link class="menulink" to="/Book" >  {{ Ordertt[sitelanguage] }} </router-link> 
                         

                    </div>  



     </div>  

   
    

  </div>




<div class="bottom">

<router-view></router-view>
</div>

 
</div>

  
  


</template>






<script>


//import {createNamespacedHelpers} from 'vuex'




//const { mapState, mapActions } = createNamespacedHelpers('auth');

import {createNamespacedHelpers} from 'vuex';

const dictionary= createNamespacedHelpers('dictionary');



export default {
  name: "Home",
  components: {},
  data() {
    return {

        languagesite2:"tr", 
        Dictionarytt:{en:'Dictionary',fr:'Dictionnaire',tr:'Sözlük',de:'Wörterbuch',nl:'Woordenboek',kc:'Sözlük'},
        Purposett:{en:'Purpose',fr:'Objectif',tr:'Amaç',de:'Zweck',nl:'Doel',kc:'Amaç'},
        Alphabettt:{en:'Alphabet',fr:'Alphabet',tr:'Alfabe',de:'Alphabet',nl:'Alfabet',kc:'Alfabe'},
        Infott:{en:'Information',fr:'Informations',tr:'Bilgi',de:'Information',nl:'Informatie',kc:'Bilgi'},
        Ordertt:{en:'Order Book',fr:'Commande livre',tr:'Kitap siparişi',de:'Buchbestellung',nl:'Boek bestellen',kc:'Kitap siparişi'},

    };
  },


  computed: {
     ...dictionary.mapState(['sitelanguage']),

//     ...dictionary.mapState([{languagesite2: state=>state.sitelanguage}]),
  },


  methods: {
    ...dictionary.mapActions(['setsitelanguage']), 

    updatelanguage(){
        this.setsitelanguage(this.languagesite2);
        console.log(this.sitelanguage);
        
    }

   },

   created () {
        this.languagesite2 = this.sitelanguage  ;


        },

  mounted() {
   
  this.$router.push({name:'Kirmanc'});

  },


 
};
</script>

<style>
.master{
  display: flex;
  flex-direction: column;
}

.top{
  display: flex;
  flex-direction: column;
  justify-content: left;

  color: white; 
  background:darkblue;

}

.toptop{
  display: flex;
  justify-content:space-between;
  
}
.topbottom{
  display: flex;
  justify-content: right;
  padding-right: 10px;

  
}

.topright{
  display: flex;
  flex-direction: column;
  flex:4;
  color: white; 
  background:darkblue;
}

.topright_top {
  display: flex;
  justify-content: right;
  

  color: white; 
  background:darkblue;
}

.topright_bottom {
  display: flex;
  justify-content: right;
 
  color: white; 
  background:darkblue;
}


.topleft{
  display: flex;
  flex:1;
  display: flex;
  justify-content: left;
  margin-top: 10px;
 
  color: white; 
  background:darkblue;
}

.menulink{
text-decoration: none;
margin : 0 0 10px 20px;
color: white;
}

menulink:hover, menulink:active {
   font-weight: bold;
   color:rgb(124, 226, 247);
}

.sitelanguageselect{
height:35px;
width:150px;
margin-top:10px;
margin-right:10px;
}

.bottom{
 display:flex;
 flex-direction: column;

 align-self: center;
 justify-content: center;
 align-content: center;
 align-items: center;
}
</style>