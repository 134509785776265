<template>
  
  <div class="alfabetdiv">
    <br>
    <h1>
        SUNUŞ
  </h1>    
  <br>

<div class="alfalist" > 
       
   
 
 <p class="paragraph">
  
Son yıllarda Kırmancki mi, Zazaca mı denilmeli diye bir tartışma yürütülmektedir bu dilin üzerinde. Halk tarafından kabul gören ve doğru

bilinen adlandırma kırmanki veya kırmanccadır. Dilimizin esas ismi Zazaca değildir. Zazaca demek çok kötü bir adlandırma değildir. Ancak

doğru da değildir. Esas doğru olan adlandırma, dilin adı Kırmancki ve

bu dili konuşanlara da Kırmanc denir. Zazaca, Kurmacların ve kırmanclara konuşurken J harfi yerine daha çok Z harfini kullandıkları için Zaza yakıştırmasında bulununarak taktıkları bir isimlendirmedir.

<br><br>

Kırmancca üzerine şimdiye kadar hiçbir ciddi araştırma ve inceleme
yapılmamıştır. Birkaç yabancının yaptığı araştırma ise üstün körüdür.

Daha çok güney Kırmancları ile Kurmancı konuşanların bilgisine başvulmuştur. Hiçbir araştırmacı en çok Kırmancca konuşulan Dersim’e

ve Bingöl’e uğramamıştır bile. Uzun zamandır bu konuda araştırma ve

incelemelerimden ziyade, kendi yaşamım ve tecrübelerimden de bu gerçeği bizzat yaşayarak gördüm. Dersim’de doğdum. Türkçeyi okula başlarken öğrendim. Kürdistan’ın birçok ilinde bulundum.

Öğretmenliğimin ilk yılı Bingöl merkez köyü eski ismiyle Kürman, yeni ismiyle Yelesen olan ve çok az sayıda kişinin Türkçe bildiği “Zaza”

köyünde bulundum. Hep Kırmancların ve hem de Kurmancların içinde yaşadım. Diyarbakır zindanında bile dile olan ilgim devam etti. Çocukluğumdan beri Kırmancki ile ilgili gözlem ve incelemelerim devam

etti. Bölge halkına sorulduğunda, “bize kırmac denir, konuştuğumuz dile ise kırmancki denir” diye yanıtlarlar. Bölgede yaşlılara sorulduğunda hep bu yanıt alınırdı.

<br><br>
Ben bu sözlüğü hazırlarken daha çok Kırmancki dilinin aslına daha

sadık kalındığı ve orjinalitesinin daha az bulduğuna ve dilin aslına sadık kaldığına inandığım Hozat-Ovacık yöresindeki konuşma ve telafuzu esas almaya çalıştım.

Bu sözlükte iki dilde Kırmancki-Tırki olarak oturmuş ortak kelimeleri değiştirmeden olduğu gibi kullandım. Yazı dili olarak kullanılmadığı

için ölü diller içine girme noktasına gelmiştir. Yazı dili olmadığı için
her yöredeki konuşmalarda farklılıklar oluşmuştur. Bu faklılıklar doğaldır. Bu dil üzerine yapılan tüm çalışmalar değerlidir. Ortak bir telefuz

ve kullanım, uzun süreli bilimsel çalışmalarla gerçekleşebilir.
<br><br>

Kırmanki veya Kırmanca incelediğinde çok zengin bir dil olduğu görülecektir. Örneğin söylenen her türkü veya melodi için;
<br><br>
Kılami: Tükü denek, kılamkı söyle demektir aynı zamanda . Yani duygu ve düşünceni ifade et denildiğinde, kılamkı denir. Türkçe karşılığı;

kelam et. Kılam, aynı zamanda söz, kelime de demektir.
<br><br>
Şuari: Ağıt, şin ile şıvan kelimelerinden gelir. Şinu-şıvan; ana baba
günü veya kıyamet koptunun karşılığına denk düşer.
<br><br>
Zibari: Ölü olduğu zaman, ölünün üstünde ağlamak için söylenen ağıtlardır. Jibayıs kelimesinden gelir. Jıbayıs; duyulan acı karşısındaki inleme veya ağlama demektir.
<br><br>
Qeyda: Oyun havasıdır. Qey, bakma, da verme; Ya da kay, oyun, da
verme kayda oyun verme, oyun verme veya oyun oynama demektir.
<br><br>
Lawuki: Türkü demektir.
<br><br>
Deşi: Dini ilahiler denebilir. Ancak Alevi Kırmancların cem ve dini
ibadetlerinde söyledikleri ilahivari müzik çeşidi veya türkü denilebilir.
<br><br>
De veya tey; beraber anlamına geliyor. şi ise gitti anlamına gelir. Deşi,
de ve şi nin birleşmesnden oluşur. Beraber gitme anlamını verir.Bunu
tewt şiyede dayandıra biliriz. Tewt cemlerde bazı dede veya dervişlerin

ibadet sırasında kendinden geçip tanrıyla birleştiklerine inandıkları ibadetin en koyu halidir. Zirvesidir. Deşi , tewtşi den kaynaklandığı şeklinde yorumlanır.
<br><br>
Destani: destan, kahramanlık türküleridir. Destan yaratmadan gelir.
<br><br>
Cegname: savaş ve cenk türküleri veya bunlara maş da denilebilinir.
Ceng: savaş demektir ve ceng kelimesinden gelir.
Ninıki: Çocukların uyutulması için söylenen melodilerdir. nini nini,
yani uyu uyu demekten gelir.
<br><br>
Lorıki: Çocuklara süylenen türkülerdir. Lorık veya leyrık kelimelerinden gelir. Lorık veya leyrık: yavru demektir. Lorıki, çocukname anlamına gelir.
<br><br>
Bu örnekler bize Kırmaçcanın çok zengin bir dil olduğunu gösterdiği gibi, zorlama değil, her kelimenin dayandığı temeller, dayandığı köken ve beslendikleri kaynaklar oldukça mantıklı ve muazzam bir uyum
içinde olduğu görülür.
Ayrıca dilin geliştirilmesi, dil üzerinde çalışanları bekleyen acil bir

görevdir. Eski geleneklerde yeni yıl kutlaması, yaş günü vs. yoktu. Bugün tüm insanların hayatına girmiştir.
<br><br>
Yeni yılın kutlu olsunun kırmanckide ki karşılığı; sera tuya newiye
xerbo.
<br><br>
Yaş günün kutlu olsun un karşılığı ise, roca sera to xerbo.
<br><br>
Bugün bu terimler artık geniş bir kesim tarafından kabullenmiş ve
günlük hayatta kulanılmaktadır. Dilin mantığına da uygundur.

  
 </p> 
         

</div>



<div class="alfalist"  v-if="false"> 
       
   
 
       <p class="paragraph">
        
        In recent years, there has been a debate over whether it should be called Kırmancki or Zazaki. 
        The naming that is accepted by the public and known correctly is kirmanki or kirmanca. 
        The main name of our language is not Zazaki. Zazaki is not such a bad naming. 
        However, it is not true either. The main correct naming is the name of the language Kırmancki and
        Those who speak this language are also called Kırmanc. 
        Zaza is a name given by the Fictionaries and the cursing people, as they use the letter Z instead of the letter J, by referring to it as Zaza.
        
        <br><br>
      
      No serious research or study has been done on Kırmancca so far. The research done by a few foreigners, on the other hand, is superficial.   
      The information of the Southern Kirmanjis and Kurmanji speakers was mostly sought. No researcher has even visited Dersim and Bingöl, where the most Kirman language is spoken. For a long time, I have seen this fact from my own life and experiences, rather than my research and studies on this subject. I was born in Dersim. I learned Turkish when I started school. I have been to many provinces of Kurdistan.
      In the first year of my teaching career, I was in the village of "Zaza", which was known as Kürman in the central village of Bingöl and with the new name Yelesen, and where very few people speak Turkish. I have always lived among the Kirmancs and the Kurmancs. Even in Diyarbakir dungeon, my interest in language continued. Since my childhood, my observations and investigations about Kırmancki continued.  
      When the people of the region are asked, they answer, "We are called "Kırmanc", and the language we speak is called "Kırmancki". When asked to the elderly in the region, this answer was always received.
      
      <br><br>
  While preparing this dictionary, I tried to base the speech and pronunciation of the Hozat-Ovacık region, where I believe that the Kırmancki language is more faithful to its original, less originality, and the language remains faithful to its original.
In this dictionary, I used the common words in two languages ​​as Kırmancki-Tırki without changing them. 
Since it is not used as a written language, it has come to the point of entering into dead languages. 
Since there is no written language, there are differences in speech in each region. 
These differences are natural. All studies on this language are valuable. 
A common pronunciation and use can be achieved through long-term scientific studies.

      <br><br>
      
      When you examine Kirmanki or Kirman, it will be seen that it is a very rich language. For example, for every song or melody sung;
      <br><br>

      Kılami: Tükü denek, kılamkı söyle demektir aynı zamanda . Yani duygu ve düşünceni ifade et denildiğinde, kılamkı denir. Türkçe karşılığı;
      
      kelam et. Kılam, aynı zamanda söz, kelime de demektir.
      <br><br>
      Şuari: Ağıt, şin ile şıvan kelimelerinden gelir. Şinu-şıvan; ana baba
      günü veya kıyamet koptunun karşılığına denk düşer.
      <br><br>
      Zibari: Ölü olduğu zaman, ölünün üstünde ağlamak için söylenen ağıtlardır. Jibayıs kelimesinden gelir. Jıbayıs; duyulan acı karşısındaki inleme veya ağlama demektir.
      <br><br>
      Qeyda: Oyun havasıdır. Qey, bakma, da verme; Ya da kay, oyun, da
      verme kayda oyun verme, oyun verme veya oyun oynama demektir.
      <br><br>
      Lawuki: Türkü demektir.
      <br><br>
      Deşi: Dini ilahiler denebilir. Ancak Alevi Kırmancların cem ve dini
      ibadetlerinde söyledikleri ilahivari müzik çeşidi veya türkü denilebilir.
      <br><br>
      De veya tey; beraber anlamına geliyor. şi ise gitti anlamına gelir. Deşi,
      de ve şi nin birleşmesnden oluşur. Beraber gitme anlamını verir.Bunu
      tewt şiyede dayandıra biliriz. Tewt cemlerde bazı dede veya dervişlerin
      
      ibadet sırasında kendinden geçip tanrıyla birleştiklerine inandıkları ibadetin en koyu halidir. Zirvesidir. Deşi , tewtşi den kaynaklandığı şeklinde yorumlanır.
      <br><br>
      Destani: destan, kahramanlık türküleridir. Destan yaratmadan gelir.
      <br><br>
      Cegname: savaş ve cenk türküleri veya bunlara maş da denilebilinir.
      Ceng: savaş demektir ve ceng kelimesinden gelir.
      Ninıki: Çocukların uyutulması için söylenen melodilerdir. nini nini,
      yani uyu uyu demekten gelir.
      <br><br>
      Lorıki: Çocuklara süylenen türkülerdir. Lorık veya leyrık kelimelerinden gelir. Lorık veya leyrık: yavru demektir. Lorıki, çocukname anlamına gelir.
      <br><br>
      Bu örnekler bize Kırmaçcanın çok zengin bir dil olduğunu gösterdiği gibi, zorlama değil, her kelimenin dayandığı temeller, dayandığı köken ve beslendikleri kaynaklar oldukça mantıklı ve muazzam bir uyum
      içinde olduğu görülür.
      Ayrıca dilin geliştirilmesi, dil üzerinde çalışanları bekleyen acil bir
      
      görevdir. Eski geleneklerde yeni yıl kutlaması, yaş günü vs. yoktu. Bugün tüm insanların hayatına girmiştir.
      <br><br>
      Yeni yılın kutlu olsunun kırmanckide ki karşılığı; sera tuya newiye
      xerbo.
      <br><br>
      Yaş günün kutlu olsun un karşılığı ise, roca sera to xerbo.
      <br><br>
      Bugün bu terimler artık geniş bir kesim tarafından kabullenmiş ve
      günlük hayatta kulanılmaktadır. Dilin mantığına da uygundur.
      
        
       </p> 
               
      
      </div>
      




    </div>

</template>






<script>


import {createNamespacedHelpers} from 'vuex';


const dictionary= createNamespacedHelpers('dictionary');



export default {
  name: "Information",
  components: {},
  data() {
    return {
    
    
    };
  },

  computed: {
   ...dictionary.mapState(['sitelanguage']),
  },


  methods: {
    checkl(llg){
       if (this.sitelanguage==llg) { return true} else {return false};
    }
   },
 
};
</script>


<style scoped>
.alfabetdiv {
 display: flex;
 flex-direction: column;
 align-items: center;
 padding-top: 30px;
 padding-left: 10px;
 padding-right: 10px;

}

.alfalist{
display:flex;
flex-direction: column;
align-items:flex-start;
max-width: 700px;

}

.paragraph{
text-align: left;

}


</style>