<template>
  
    <div class="alfabetdiv">
      <br>  <h1>    Sitenin amacı  </h1>    
    <br>
  
  <div class="alfalist" > 
         
     
   <p class="paragraph">
        Kimi yerde Kırmaçki, kimi yerde Zaza ve kimi yerde Dımıli olarak adlandırılan, ancak esata aynı olan bu dili geliştirmek ve zenginleştşrmektir.
        Bunu zenginleştirmek kollektif bir çabanın ürünü olacaktır.
        Herkes buna katkıda bulunabilecektir.  Buna kelime önerebilir veya ekleyebilir.
        <br>
        <br>
        Şu anda beş dilde (Almanca, Fransızca, İngilizce, Hollandaca ve Türkçe) tercüme yapılmaktadır.
        <br>
        <br>
        Www.kirmanc.info nun amacı zengin bir sözlük oluşturmak.
        Kırmancki (zaza, dımıli) dilini uluslararası alandaki yerine oturtmak.
        UNESCO tarafından da kaybolmakta olan diller grubunda görülen Kırmancki (zaza, dımıli) dilinin kaybolmasının önüne geçmek ve kaybolmasını imkansız hale getirmek.
        <br>
        <br>
        Her bireyin bir kelime ile de olsa yapacağı katkılarla kollektif bir ürüne dönüştürmektir.
        Önerilen kelimeler beli aralıklarla bir kurul tarafından değerlendirilir ve sözlüğe eklenilir.
        <br>
        <br>
        Bu neden gerekli ?
        <br>
        Bunun amacı dille alakası olmayan, dilin yapısını boza saçma sapan kelimelerle bir sözcükler anarşisinin önüne geçmektir.
        <br>
        <br>
        www.kirmanc.info sitesini paylaşalım. Mümkün olduğunca en geniş kesime ulaştıralım.

   
   </p> 
           
  
  </div>
  
  
  
  <div class="alfalist"  v-if="false"> 
         
     
   
         <p class="paragraph">
          
         </p>
        
        </div>
        
  
  
  
  
      </div>
  
  </template>
  
  
  
  
  
  
  <script>
  
  
  import {createNamespacedHelpers} from 'vuex';
  
  
  const dictionary= createNamespacedHelpers('dictionary');
  
  
  
  export default {
    name: "Purpose",
    components: {},
    data() {
      return {
      
      
      };
    },
  
    computed: {
     ...dictionary.mapState(['sitelanguage']),
    },
  
  
    methods: {
      checkl(llg){
         if (this.sitelanguage==llg) { return true} else {return false}
      }
     },
   
  };
  </script>
  
  
  <style scoped>
  .alfabetdiv {
   display: flex;
   flex-direction: column;
   align-items: center;
   padding-top: 30px;
   padding-left: 10px;
   padding-right: 10px;
  
  }
  
  .alfalist{
  display:flex;
  flex-direction: column;
  align-items:flex-start;
  max-width: 700px;
  
  }
  
  .paragraph{
  text-align: left;
  
  }
  
  
  </style>