<template>
  <!-- 
    <Kirmanc />
  <Navbar />

  -->
 

  
  <Home />
  
</template>

<script>
//import Kirmanc from './components/Kirmanc.vue'

import Home from './components/Home.vue'

//import Navbar from './components/Navbar.vue'

export default {
  name: 'App',
  components: {
    // Kirmanc
    Home
 //   Navbar

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}
</style>
