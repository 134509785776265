//import Vue from "vue";

import { createWebHistory, createRouter } from "vue-router";

//import VueRouter from "vue-router";
//import store from "../store";





import Kirmanc from "../components/Kirmanc";
//import Home from "../components/Home";
import Information from "../components/Information";
import Book from "../components/Book";
import Alfabet from "../components/Alfabet";
import Purpose from "../components/Purpose";

//Vue.use(VueRouter);

const routes = [

  {
    path: "/",
    redirect: "/Kirmanc",
    name: "Kirmanc",
    component: Kirmanc,
    meta: {
        title:'Dictionary'
    }
  },



  {
    path: "/Kirmanc",
    name: "Kirmanc",
    component: Kirmanc,
    meta: {
        title:'Kirmanc'
    }
  },
  {
    path: "/Purpose",
    name: "Purpose",
    component: Purpose,
    meta: { guest: true },
  },
  {
    path: "/Information",
    name: "Information",
    component: Information,
    meta: { guest: true },
  },

  {
    path: "/Book",
    name: "Book",
    component: Book,
    meta: { guest: true },
  },

  {
    path: "/Alfabet",
    name: "Alfabey",
    component: Alfabet,
    meta: { guest: true },
  },



];




const router = createRouter({
    history: createWebHistory(),
    routes,
  });






//  createApp(SimpleRouter).mount('#app')

export default router;