//import axios from 'axios';

    function defaultstate ()  { return  {
    sitelanguage : "en", 
  
 }
}




//import auth from './auth'

const state =  defaultstate()

const getters = {

     getsitelanguage:  (state) => { return state.sitelanguage } 

 };


 const mutations = {

    SET_sitelanguage (state, language) {  
        state.sitelanguage= language      
          //  state.sitelanguage= Object.values(Object.assign({}, insurers));
         
            },
       
       
};
  
  const actions = {
    setsitelanguage  ({commit}, input)  {
        commit('SET_sitelanguage', input)
       },

   };
   
 
 
 export default {
     namespaced: true,
     state,
     getters,actions, mutations

     
 }
 